<template>
  <PageTemplate>
		<Section>
			<Card size="large">
				<CardSection>
					<Stack direction="column" space="3">
						<Heading size="1" align="center">therapeutic massage</Heading>
						<Heading size="4" align="center" color="tertiary" uppercase>Call Sedona to book your appointment today!</Heading>
						<Paragraph align="center">Spa guests much be at least 18 years of age to recieve an appointment for any of our spa treatments.</Paragraph>
						<Button label="Contact Sedona" color="primary" url="/sedona/contact" />
					</Stack>
				</CardSection>
			</Card>
			<Card size="large">
				<CardSection>
					<Grid>
						<GridContainer size="12 1@lg"></GridContainer>
						<GridContainer size="12 6@md 5@lg">
							<Images height="300px" width="100%" image="theme-sd/treatments1.jpg" />
						</GridContainer>
						<GridContainer size="12 6@md 5@lg" align="middle">
							<Stack direction="column" space="3" align="left">
								<Heading size="3" color="primary">1. Aromatherapy Massage</Heading>
								<Heading size="4">1 HOUR - $110</Heading>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</Stack>
						</GridContainer>
						<GridContainer size="12 1@lg"></GridContainer>
					</Grid>
				</CardSection>
			</Card>
			<Card size="large">
				<CardSection>
					<Grid>
						<GridContainer size="12 1@lg"></GridContainer>
						<GridContainer size="12 6@md 5@lg">
							<Images height="300px" width="100%" image="theme-sd/treatments2.jpg" />
						</GridContainer>
						<GridContainer size="12 6@md 5@lg" align="middle">
							<Stack direction="column" space="3" align="left">
								<Heading size="3" color="primary">2. Deep Tissue Massage</Heading>
								<Heading size="4">1 HOUR - $120</Heading>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</Stack>
						</GridContainer>
						<GridContainer size="12 1@lg"></GridContainer>
					</Grid>
				</CardSection>
			</Card>
			<Card size="large">
				<CardSection>
					<Grid>
						<GridContainer size="12 1@lg"></GridContainer>
						<GridContainer size="12 6@md 5@lg">
							<Images height="300px" width="100%" image="theme-sd/treatments3.jpg" />
						</GridContainer>
						<GridContainer size="12 6@md 5@lg" align="middle">
							<Stack direction="column" space="3" align="left">
								<Heading size="3" color="primary">3. Pre-Natal Massage</Heading>
								<Heading size="4">1 HOUR - $120</Heading>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</Stack>
						</GridContainer>
						<GridContainer size="12 1@lg"></GridContainer>
					</Grid>
				</CardSection>
			</Card>
			<Card size="large">
				<CardSection>
					<Grid>
						<GridContainer size="12 1@lg"></GridContainer>
						<GridContainer size="12 6@md 5@lg">
							<Images height="300px" width="100%" image="theme-sd/treatments4.jpg" />
						</GridContainer>
						<GridContainer size="12 6@md 5@lg" align="middle">
							<Stack direction="column" space="3" align="left">
								<Heading size="3" color="primary">4. Warm Stone Massage</Heading>
								<Heading size="4">1 HOUR - $140</Heading>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</Stack>
						</GridContainer>
						<GridContainer size="12 1@lg"></GridContainer>
					</Grid>
				</CardSection>
			</Card>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/sedona/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
